@import "src/core/common/assets/styles/mixins";

.root {
  margin-left: 8px;
  margin-right: 8px;
  border-radius: 20px;
  overflow: hidden;

  @include respondTo(l) {
    margin-left: 20px;
    margin-right: 20px;
  }
}

.content {
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 7px;
  background-color: var(--white-with-opacity-5);
  max-width: 1268px;
  margin-left: auto;
  margin-right: auto;

  @include respondTo(l) {
    padding: 48px 100px;
  }
}


.expertListWrapper {
  max-width: 360px;
  margin-bottom: 24px;
  width: 100%;

  @include respondTo(l) {
    max-width: none;
    margin-bottom: 34px;
  }
}

.expertList {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -17px;
  margin-right: -8px;

  @include respondTo(l) {
    margin-right: -24px;
  }
}

.loaderWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.expertListItem {
  flex: 0 0 auto;
  width: 50%;
  max-width: 100%;
  padding-bottom: 17px;
  padding-right: 8px;

  @include respondTo(l) {
    width: calc(100% / 3);
    padding-right: 24px;
  }
}

.expert {
  @include forceTheme(d);

  height: 100%;
}

.button {
  width: 100%;
  background: var(--primary);
  color: var(--typography-900);
  font-weight: 600;
  height: 44px;
  padding-right: 48px;
  padding-left: 48px;

  @include respondTo(l) {
    font-size: 18px;
    line-height: 21px;
    height: 56px;
  }
}

.buttonWrapper {
  min-width: 320px;
  margin: 0 auto;
}
