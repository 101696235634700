@import "src/core/common/assets/styles/mixins";

.content {
  @include sectionSidePaddings();

  display: flex;
  flex-direction: column;
  align-items: center;

  @include respondTo(l) {
    padding-left: 21px;
    padding-right: 21px;
  }
}

.title {
  color: var(--typography-100);
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 32px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;

  @include respondTo(l) {
    text-align: center;
    font-size: 40px;
    line-height: 47px;
    margin-bottom: 60px;
  }
}

.items {
  max-width: 825px;
  margin-left: auto;
  margin-right: auto;

}

.item {
  padding-left: 32px;
  position: relative;
  margin-bottom: 24px;

  @include respondTo(l) {
    padding-left: 65px;
    margin-bottom: 40px;
  }

  &::before {
    content: '';
    position: absolute;
    left: 0;
    width: 24px;
    height: 24px;
    background-image: url('/images/point-star.svg');
    background-repeat: no-repeat;
    background-size: contain;

    @include respondTo(l) {
      width: 40px;
      height: 40px;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}


.itemTitle {
  color: var(--typography-100);
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 4px;

  @include respondTo(l) {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 12px;
  }
}

.itemDescription {
  color: var(--typography-200);
  font-size: 12px;
  line-height: 16px;

  @include respondTo(l) {
    font-size: 18px;
    line-height: 24px;
  }
}
