@import "src/core/common/assets/styles/mixins";

.root {
  border-radius: 20px;
  padding-left: 8px;
  padding-right: 8px;

  @include respondTo(l) {
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.container {
  max-width: 1326px;
  width: 100%;
  border-radius: 20px;
  margin-left: auto;
  margin-right: auto;
  background: var(--white-with-opacity-5);
}

.content {
  display: flex;
  flex-direction: column-reverse;

  @include respondTo(l) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.textColumn {
 padding: 32px 15px;
  display: flex;
  justify-content: center;
  flex: 1 2;

  @include respondTo(l) {
    padding: 77px 50px 77px 80px;
  }
}

.title {
  color: var(--typography-100);
  font-size: 22px;
  line-height: 30px;
  margin-bottom: 8px;
  font-weight: 700;

  @include respondTo(l) {
    font-size: 32px;
    line-height: 44px;
    margin-bottom: 12px;
  }
}

.description {
  color: var(--typography-100);
  font-size: 14px;
  line-height: 21px;

  @include respondTo(l) {
    font-size: 18px;
    line-height: 27px;
  }
}

.textContent {
  max-width: 600px;
}

.imageColumn {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1;
  padding-top: 28px;
  padding-bottom: 28px;
  background-color: var(--primary-300);
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;

  @include forceTheme(d);

  @include respondTo(l) {
    padding-top: 0;
    padding-bottom: 0;
    max-width: 534px;
    width: 100%;
    min-height: 310px;
    border-bottom-right-radius: 20px;
    border-top-left-radius: 0;
  }
}

.imageDesktop {
  @include positionFutureImage(240px);
  @include hideOnMobile();
}

.imageMobile {
  @include positionFutureImage(120px);
  @include hideOnDesktop();
}
