@import "src/core/common/assets/styles/mixins";

.content {
  @include pageContainerMaxWidth(1208px);
  @include sectionSidePaddings();

  display: flex;
  flex-direction: column;
  align-items: center;

  @include respondTo(l) {
    padding-left: 21px;
    padding-right: 21px;
  }
}

.title {
  color: var(--typography-100);
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 32px;
  text-align: center;

  @include respondTo(l) {
    font-size: 40px;
    line-height: 47px;
    margin-bottom: 40px;
  }
}

.items {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 32px;
  width: 100%;

  @include respondTo(l) {
    align-items: flex-start;
    flex-direction: row;
    margin-left: -21px;
    margin-right: -21px;
  }
}

.item {
  max-width: 300px;

  @include respondTo(l) {
    width: 25%;
    padding-left: 21px;
    padding-right: 21px;
    max-width: none;
  }
}

.itemInner {
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include respondTo(l) {
    margin-bottom: 0;
  }
}

.itemIconWrapper {
  border-radius: 50%;
  overflow: hidden;
  width: 40px;
  height: 40px;
  margin-bottom: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  @include respondTo(l) {
    width: 60px;
    height: 60px;
  }
}

.itemIcon {
  object-fit: cover;
}

.itemTitle {
  color: var(--typography-100);
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  margin-bottom: 4px;
  text-align: center;

  @include respondTo(l) {
    font-size: 20px;
    line-height: 27px;
    margin-bottom: 8px;
  }
}

.itemDescription {
  color: var(--typography-200);
  font-size: 12px;
  line-height: 16px;
  text-align: center;

  @include respondTo(l) {
    font-size: 14px;
    line-height: 19px;
  }
}

.button {
  width: 100%;
  background: var(--primary);
  color: var(--typography-900);
  font-weight: 600;
  height: 44px;
  justify-content: space-between;
  padding-right: 48px;
  padding-left: 48px;
  font-size: 18px;
  line-height: 21px;
}

.buttonLabel {
  width: 100%;
}

.buttonWrapper {
  width: 100%;
  max-width: 320px;
  margin: 0 auto;
}

.icon {
  width: 18px;
  height: 18px;
  fill: currentColor;
}

